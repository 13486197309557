const solutions = [
  {
    id: "1",
    label: "集团总指挥中心",
    link: "/solution/1",
  },
  {
    id: "2",
    label: "项目总指挥中心",
    link: "/solution/2",
  },
  {
    id: "3",
    label: "全场景：劳务实名制管理解决方案",
    link: "/solution/3",
  },
  {
    id: "4",
    label: "全风险：安全管理解决方案",
    link: "/solution/4",
  },
  {
    id: "5",
    label: "全生命：质量管理解决方案",
    link: "/solution/5",
  },
  {
    id: "6",
    label: "工人教育解决方案",
    link: "/solution/6",
  },
  {
    id: "7",
    label: "电子合同解决方案",
    link: "/solution/7",
  },
  {
    id: "8",
    label: "定制化服务解决方案",
    link: "/solution/8",
  },
];

export default solutions;
