<template>
  <div>
    <div
      :class="['page', sideBarVisible ? 'z-min' : '']"
      @click="sideBarVisible = false"
    >
      <router-view></router-view>
    </div>
    <div
      class="entry_handler"
      flex="dir:top main:center cross:center"
      @click="sideBarVisible = true"
    >
      <img class="i" src="@/assets/img/ic_收起@3x.png" alt="展开" />
      <div class="t" v-for="t in '查看目录'" :key="t">{{ t }}</div>
    </div>
    <div
      :class="['side_box', sideBarVisible ? 'z-show' : '']"
      flex="dir:top cross:bottom"
    >
      <div
        class="hide_btn"
        @click="sideBarVisible = false"
        flex="main:center cross:center"
      >
        <img class="i" src="@/assets/img/ic_收起@3x.png" alt="收起" />
        <div class="t">收起</div>
      </div>
      <div class="list">
        <router-link
          class="item"
          :to="item.link"
          v-for="item in solutions"
          :key="item.id"
        >
          <span class="arr" />{{ item.label }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import solutions from "@/data/solutions";
export default {
  data() {
    return {
      solutions: [...solutions],
      sideBarVisible: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.sideBarVisible = true;
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.page {
  margin-top: $navHeight;
  transition: margin-left 0.3s;

  &.z-min {
    margin-left: 18rem;
  }
}

.entry_handler {
  position: fixed;
  top: 50%;
  left: 0.6rem;
  width: 2.4rem;
  height: 8.9rem;
  margin-top: -4.45rem;
  background-color: rgba(27, 45, 75, 0.6);
  border-radius: 0.6rem;
  backdrop-filter: blur(0.25rem);
  cursor: pointer;

  .i {
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .t {
    opacity: 0.8;
    font-size: 0.8rem;
    font-weight: bold;
    color: #fff;
    line-height: 1.2rem;
  }
}

.side_box {
  position: fixed;
  top: $navHeight;
  left: -18rem;
  bottom: 0;
  width: 18rem;
  background-color: #1b2d4b;
  padding: 2rem;
  box-sizing: border-box;
  transition: transform 0.3s;

  &.z-show {
    transform: translate3d(18rem, 0, 0);
  }

  .hide_btn {
    cursor: pointer;

    .i {
      display: block;
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.3rem;
      transform: rotate(180deg);
    }

    .t {
      opacity: 0.8;
      font-size: 0.8rem;
      color: #fff;
      line-height: 1.2rem;
    }
  }

  .list {
    margin-top: 2rem;
    width: 100%;

    .item {
      position: relative;
      display: block;
      height: 3.1rem;
      line-height: 3.1rem;
      text-decoration: none;
      font-size: 0.8rem;
      text-align: left;
      color: rgb(255, 255, 255, 0.8);
      margin-top: -1px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #fff;
        opacity: 0.3;
      }

      &::before {
        top: 0;
      }

      &::after {
        display: none;
        bottom: 0;
      }

      &:last-child::after {
        display: block;
      }

      &:hover {
        color: #fff;
      }

      &.router-link-active {
        color: #fff;
        text-align: right;

        .arr {
          display: block;
        }

        &::before {
          opacity: 1;
        }

        &::after {
          display: block;
          opacity: 1;
        }
      }

      .arr {
        display: none;
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        margin-top: -0.35rem;
        border: 0.35rem solid transparent;
        border-left-width: 0.5rem;
        border-left-color: #fff;
        border-right-width: 0;
      }
    }
  }
}
</style>
